import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import StyledHero from "../components/styledHero"
import SEO from "../components/seo"
import Title from "../components/title"
import styles from "../css/text.module.css"

const Imprint = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Impressum" />
      <StyledHero img={data.imprintBcg.childImageSharp.fluid} />
      <section className={styles.text}>
        <Title title="Impressum" />
        <div className={styles.center}>
          <article>
            <p>Informationspflicht laut § 5 TMG.</p>
            <p>
              Beceptum International GmbH
              <br />
              Trautenwolfstr. 5<br />
              80802 Munich, Germany
            </p>
            <p>
              Telefon: +49 89 / 330 790 61
              <br />
              E-Mail: info@beceptum.com
            </p>
            <p>Vertreten durch: Marc Zinkel</p>
            <p>
              Registereintrag: Eintragung im Handelsregister
              <br />
              Registergericht: München
              <br />
              Registernummer: HRB 249723
              <br />
              Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
              DE324976335
            </p>
            <p>und</p>
            <p>
              knooing GmbH
              <br />
              Claudius-Keller-Str. 3b
              <br />
              81669 München
            </p>
            <p>
              Telefon: +49 89 143 792 90
              <br />
              E-Mail: info@knooing.de
            </p>
            <p>Vertreten durch: Carsten Hochschon</p>
            <p>
              Registereintrag: Eintragung im Handelsregister
              <br />
              Registergericht: München
              <br />
              Registernummer: HRB 208023
              <br />
              Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
              DE291729732
            </p>
            <p>und</p>
            <p>
              ISG Information Services Group Germany GmbH
              <br />
              The Squaire 12,
              <br />
              Am Flughafen 60549 Frankfurt a. M.
            </p>
            <p>
              Telefon: +49 69 6976900
              <br />
              E-Mail: kontakt@isg-one.com
            </p>
            <p>Vertreten durch: Barbara Florschütz</p>
            <p>
              Registereintrag: Eintragung im Handelsregister
              <br />
              Registergericht: Frankfurt am Main
              <br />
              Registernummer: HRB 94888
              <br />
              Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
              DE 113835845
            </p>
          </article>
        </div>
      </section>
      <section className={`${styles.text} ${styles.even}`}>
        <div className={styles.center}>
          <article>
            <h4>EU-Streitschlichtung</h4>
            <p>
              Gemäß Verordnung über Online-Streitbeilegung in
              Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über
              die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
              Verbraucher haben die Möglichkeit, Beschwerden an die Online
              Streitbeilegungsplattform der Europäischen Kommission unter
              <a
                href="http://ec.europa.eu/odr?tid=321166728"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                http://ec.europa.eu/odr?tid=321166728{" "}
              </a>
              zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb
              in unserem Impressum. Wir möchten Sie jedoch darauf hinweisen,
              dass wir nicht bereit oder verpflichtet sind, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </p>
            <h4>Haftung für Inhalte dieser Website</h4>
            <p>
              Wir entwickeln die Inhalte dieser Webseite ständig weiter und
              bemühen uns korrekte und aktuelle Informationen bereitzustellen.
              Laut Telemediengesetz (TMG) §7 (1) sind wir als Diensteanbieter
              für eigene Informationen, die wir zur Nutzung bereitstellen, nach
              den allgemeinen Gesetzen verantwortlich. Leider können wir keine
              Haftung für die Korrektheit aller Inhalte auf dieser Webseite
              übernehmen, speziell für jene die seitens Dritter bereitgestellt
              wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir
              nicht verpflichtet, die von ihnen übermittelten oder gespeicherten
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen. Unsere Verpflichtungen
              zur Entfernung von Informationen oder zur Sperrung der Nutzung von
              Informationen nach den allgemeinen Gesetzen aufgrund von
              gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle
              unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.
              Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen,
              bitte wir Sie uns umgehend zu kontaktieren, damit wir die
              rechtswidrigen Inhalte entfernen können. Sie finden die
              Kontaktdaten im Impressum.
            </p>
            <h4>Haftung für Links auf dieser Website</h4>
            <p>
              Unsere Webseite enthält Links zu anderen Webseiten für deren
              Inhalt wir nicht verantwortlich sind. Haftung für verlinkte
              Websites besteht für uns nicht, da wir keine Kenntnis
              rechtswidriger Tätigkeiten hatten und haben, uns solche
              Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir
              Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt
              werden. Wenn Ihnen rechtswidrige Links auf unserer Website
              auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die
              Kontaktdaten im Impressum.
            </p>
            <h4>Urheberrechtshinweis</h4>
            <p>
              Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
              unterliegen dem Urheberrecht der Bundesrepublik Deutschland. Bitte
              fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten,
              vervielfältigen oder verwerten wie zum Beispiel auf anderen
              Websites erneut veröffentlichen. Falls notwendig, werden wir die
              unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
              verfolgen. Sollten Sie auf dieser Webseite Inhalte finden, die das
              Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.
            </p>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    imprintBcg: file(relativePath: { eq: "imprintBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Imprint
